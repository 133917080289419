import React, { useState, useEffect } from 'react';
import ChatLocation from './ChatLocation.js';
import ChatDefault from './ChatDefault.js';
import Loading from '../Loading'; // 로딩 컴포넌트 임포트
import LoadableComponent from '../LoadableComponent'; // 로딩 관리 컴포넌트 임포트

function ChatMain() {
    const [chatLocationLoaded, setChatLocationLoaded] = useState(false);
    const [chatDefaultLoaded, setChatDefaultLoaded] = useState(false);
    const [allComponentsLoaded, setAllComponentsLoaded] = useState(false);

    useEffect(() => {
        // 모든 컴포넌트가 로딩되었는지 확인
        setAllComponentsLoaded(chatLocationLoaded && chatDefaultLoaded);
    }, [chatLocationLoaded, chatDefaultLoaded]);

    return (
        <div>
            {!allComponentsLoaded && <Loading loading={!allComponentsLoaded} />} {/* 로딩 중 표시 */}
            <LoadableComponent Component={ChatLocation} onLoad={() => setChatLocationLoaded(true)} />
            <LoadableComponent Component={ChatDefault} onLoad={() => setChatDefaultLoaded(true)} />
        </div>
    );
}

export default ChatMain;

