import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Button, Image, Ratio, Dropdown, Form} from 'react-bootstrap';
import {PinAngle, LightbulbFill, ChevronDown, Filter} from 'react-bootstrap-icons';
import GoBack from '../GoBack.js';
import axios from 'axios';
import ClassView from './ClassView.js';
import styled from 'styled-components';


const fontSizeSmall={fontSize: '0.6rem', width: '100%', marginRight:'0', padding:0};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const classBody = {backgroundColor: '#efefef', marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '0'};
const classAll = {textAlign: 'center', marginRight: '1px', marginLeft:'1px', marginBottom: '15px', width:'32.5%'};
const fontSmall = {fontSize:'0.6rem'};
const marginTop2 = {marginTop: '2px'};
const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none !important; // 화살표 숨기기
  }
`;
const fontSize08 = {fontSize:'0.8rem'};


const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedMonth}월 ${formattedDay}일 ${formattedHours}:${formattedMinutes}`;
};

function checkType(MyType){
        let category = 0;

        switch(MyType) {
                case 'ClassAmity': category = "/ClassAmity.png"; break;
                case 'ClassExercise': category = "/ClassExercise.png"; break;
                case 'ClassHobby': category = "/ClassHobby.png"; break;
                case 'ClassEtc': category = "/ClassEtc.png"; break;
                default: category = "/ClassEtc.png"; break;
        }

        return category;
}


function MyClass() { 

    const [myClassList, setMyClassList] = useState([]);
    const [page, setPage] = useState(0);
    const [dataCheck, setDataCheck] = useState(false);
    const [myUid, setMyUid] = useState('');
    const [filterType, setFilterType] = useState('default');
    const [checked, setChecked] = useState(false);

    useEffect(() => {
	console.log('here');

	const fetchData = async () => {
	    try{
		const response = await axios.post(`${apiUrl}/myClassAll`, { myToken: token, pageNum : page, filterType: filterType, checked: checked });
		if(response.data){
			setMyClassList(prevMyClassList => [...prevMyClassList, ...response.data.myClassData]);
			setDataCheck(true);
			console.log('response Data :', response.data);
			setMyUid(response.data.myUid);
		}else{
			console.error('No data returned of not an array;');
		}
	    }catch(error){
		console.error('1-2 : Error fetching data: ', error);
	    }
	};
	fetchData();

    },[page, filterType, checked]);

        const ViewMyClassList = myClassList.map((v, index)=> (
                <Col key={index} style={classAll} xs={4}>
                    <div className="text-center" style={fontSmall}>
                    </div>
                    <Row>
                        <Row>
                        </Row>
                        <Row style={classBody}>
                            {
                                    myUid === v.cOpenUser
                                    ? <div style={{position: 'relative'}}>
				    <LightbulbFill style={{position:'absolute', right:'5px', top:'5px', color: '#ffaf1a', zIndex: 1}} size={15}/>
                                    </div>
                                    : null
                            }
                            <Ratio aspectRatio="1x1" style={marginTop2}>
                                <Image src={checkType(v.ctype)} fluid rounded />
                            </Ratio>
                            <div>{v.cTitle}</div>
                            <div>{formatDate(v.cOpenDate)}</div>
                            <div>참여인원: ({v.cNowUser.length+1}/{v.cTotalNum})</div>
                        </Row>
                        <Row style={buttonSpace0}>
                            <Button variant="secondary" style={fontSizeSmall2} onClick={() => handleViewClick(v.classId)}>
                                내용보기
                            </Button>
                        </Row>
                    </Row>
                </Col>
        ));


        const [viewClassCheck, setViewClassCheck] = useState(false);
        const [viewClassId, setViewClassId] = useState(null);

        const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

        const handleCloseView = () => {
                setViewClassCheck(!viewClassCheck);
        };

	const handleMoreClass = () => {
		setPage(page+1);
		console.log('page :', page);
	};

	const [filterText, setFilterText] = useState('정렬방법 선택');
	const handleFilter = async(filter) => {
		switch(filter){
			case 'near' :
				setFilterText('가까운 일정보기')
				setFilterType(filter);
				setMyClassList([]);
				break;
			case 'far' :
				setFilterText('먼 일정보기');
				setFilterType(filter);
				setMyClassList([]);
				break;
			default :
				setFilterText('정렬방법 선택');
				setFilterType(filter);
				break;
		}
	};

	const handleCheckboxChange = (e) => {
		setChecked(e.target.checked);
		console.log('checkbox is now : ', e.target.checked);
		setMyClassList([]);
	};

	return(
	<div style={{marginBottom: '40px'}}>
	<GoBack />
	<Container>
	<Row>
		<h5><b>나의 모임</b></h5>
	</Row>
	<Row style={{marginBottom: '30px'}}>
	    <Col style={{textAlign: 'left', fontSize: '0.8rem', paddingTop: '7px'}}>
		<Form.Check id="my-checkbox" type="checkbox" checked={checked} onChange={handleCheckboxChange} label='내 모임보기' />
	    </Col>
	    <Col style={{textAlign: 'right'}}>
	    <Dropdown>
		<Dropdown.Toggle variant="outline" id="dropdown-basic" style={fontSize08}>
		    <Filter /> {filterText}
		</Dropdown.Toggle>

		<Dropdown.Menu>
		    <Dropdown.Item href="#/action-1" style={fontSize08} onClick={()=>handleFilter('near')}>가까운 일정보기</Dropdown.Item>
		    <Dropdown.Item href="#/action-2" style={fontSize08} onClick={()=>handleFilter('far')}>먼 일정보기</Dropdown.Item>
		</Dropdown.Menu>
	    </Dropdown>
	    </Col>
	</Row>
	    <div>
		<Row className={fontSizeSmall}>
		    {dataCheck && ViewMyClassList}
		</Row>
	    </div>
	    <Button variant='outlined' style={{color: 'black', fontSize: '0.8rem', fontWeight: 'bold', width:'60%'}} onClick={()=>handleMoreClass()}> 더보기 <ChevronDown /> </Button> 
	</Container>
        {viewClassCheck && (
                <div>
                    <ClassView classId={viewClassId} onCloseView={handleCloseView}/>
                </div>
        )}

	</div>
	);
}

export default MyClass;
