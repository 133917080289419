import {Row, Col, Button, Image, Ratio, Container, Modal, Form} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {ChevronDown, ChevronUp} from 'react-bootstrap-icons';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

function SettingReport(){

	useEffect(() => {


	},[]);

	const [clickQuestion, setClickQuestion] = useState(false);
	const handleClickQuestion = () => {
		setClickQuestion(!clickQuestion);
	};


	const [formData, setFormData] = useState({
	    email: '',
	    subject: '',
	    message: '',
	});

	const handleChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevData) => ({
	        ...prevData,
	        [name]: value,
	    }));
	};

	const [resultText, setResultText] = useState('');
	const handleSubmit = async (e) => {
	    e.preventDefault();
	    try {
		console.log(formData);
	        const response = await axios.post(`${apiUrl}/Inquiry`, {formData: formData, myToken: token});
	        if (response.status === 200) {
	            setResultText('문의사항이 성공적으로 전송되었습니다.');
	            setFormData({
	                email: '',
	                    subject: '',
	                    message: '',
	                });
	        } else {
	            setResultText('문의사항 전송에 실패했습니다.');
	        }
	    } catch (error) {
	        console.error('Error submitting inquiry:', error);
	        alert('문의사항 전송 중 오류가 발생했습니다.');
	    }
	};

	return(
	    <Container style={{marginTop: '1.5rem', textAlign:'left'}}>
		<Row onClick={()=>handleClickQuestion()}>
		    <Col>
			<h5><b>문의하기</b></h5>
		    </Col>
		    <Col xs={2} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
			{clickQuestion ?
			<ChevronUp size={20} />
			:<ChevronDown size={20} />
			}
		    </Col>
		</Row>
		{clickQuestion && (
		    <Row className="justify-content-md-center">
			<Col md={6}>
			    <Form onSubmit={handleSubmit}>
			    <Form.Group controlId="formEmail">
				<Form.Label>이메일</Form.Label>
				<Form.Control
				    style={{fontSize: '0.7rem'}}
				    type="email"
				    name="email"
				    value={formData.email}
				    onChange={handleChange}
				    placeholder="이메일을 입력하세요"
				    required
				/>
			    </Form.Group>

			    <Form.Group controlId="formSubject" className="mt-3">
				<Form.Label>문의 제목</Form.Label>
				<Form.Control
				    style={{fontSize: '0.7rem'}}
				    type="text"
				    name="subject"
			    	    value={formData.subject}
				    onChange={handleChange}
				    placeholder="문의 제목을 입력하세요"
				    required
				/>
			    </Form.Group>
			    
			    <Form.Group controlId="formMessage" className="mt-3">
				<Form.Label>문의 내용</Form.Label>
				<Form.Control
				    style={{fontSize: '0.7rem'}}
				    as="textarea"
				    name="message"
				    value={formData.message}
				    onChange={handleChange}
				    rows={5}
				    placeholder="문의 내용을 입력하세요"
				    required
				/>
			    </Form.Group>
			    <div style={{textAlign:'center'}}>
				<span style={{color:'red'}}>
				{resultText && resultText}
				</span>
			    </div>
			    <Row>
				<Col></Col>
				<Col style={{textAlign:'right'}}>
			            <Button variant="warning" type="submit" className="mt-4" style={{fontSize: '0.7rem'}}>
			   	    문의하기
			            </Button>
				</Col>
			    </Row>
			    </Form>
			</Col>
		    </Row>
		
		)}
	    </Container>
	);
}

export default SettingReport;
