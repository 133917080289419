import { Navbar, Row, Col } from 'react-bootstrap';
import {PeopleFill, PinMapFill, ChatSquareDotsFill, GearFill, X} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const xImage = {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: '10px',
      backgroundColor: 'rgb(255 255 255 / 0%)',
      zIndex: 1000, // 다른 요소 위에 위치하도록 z-index 설정
      border: 0,
      color: 'black',
};

function GoBack({onGoBack}) {
	//URI 찾기
        const navigate = useNavigate();
	const goBack = () => {
		if(onGoBack){
			console.log('GoBack : Executing onGoBack');
			onGoBack(); // 페이지 이동 전 실행할 함수
		}
		  navigate(-1);
	};
	return(
		<div>
			<button onClick={goBack} style={xImage}><X size={20} /></button>
		</div>
	);
}

export default GoBack;
