import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

const ReportMessage = ({ show, handleClose, messageId }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [alertMessage, setAlertMessage] = useState(false);
    const formRef = useRef(null);

    const handleReasonChange = (e) => {
        setSelectedReason(e.target.value);
    };

    const handleAdditionalInfoChange = (e) => {
        setAdditionalInfo(e.target.value);
    };

    const handleReset = () => {
        setSelectedReason('');
        setAdditionalInfo('');
	setAlertMessage(false);
        if (formRef.current) {
            formRef.current.reset();
        }
    };

    const handleSubmit = async (e) => {
	const token = localStorage.getItem('token');
	const response = await axios.post(`${apiUrl}/reportMessage`, { selectedReason, additionalInfo, messageId, token });
        // Handle form submission logic here
        console.log('selectedReason : ', selectedReason);
        console.log('additionalInfo : ', additionalInfo);
        console.log('messageId : ', messageId);
	console.log('alertMessage : ', response.data);
	if(response.data === 'alreadyReport'){
		setAlertMessage(!alertMessage);
	}else{
		setAlertMessage(response.data);
		handleReset();
		handleClose();
	}
    };

    useEffect(() => {
        handleReset();
    }, [show]);

    if (!show) return null; // show가 false일 때는 아무것도 렌더링하지 않음

    const placeholderStyle = {
	fontSize: '0.7rem'
    };

    return (
        <div>
            <Card style={{ width: '100%', maxWidth: '360px', margin: 'auto', marginTop: '20px', position: 'fixed', top: '7rem', zIndex: 2 }}>
                <Card.Header>
                    <div style={{ justifyContent: 'space-between', textAlign: 'center' }}>
                        <b>신고사유</b>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <Form.Check style={{ textAlign: 'left' }}>
                            <Form.Check.Input
                                type="radio"
                                id="reason1"
                                value="욕설/비방"
                                name="reportReason"
                                onChange={handleReasonChange}
                                checked={selectedReason === '욕설/비방'}
                            />
                            <Form.Check.Label htmlFor="reason1">
                                욕설/비방
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check style={{ textAlign: 'left' }}>
                            <Form.Check.Input
                                type="radio"
                                id="reason2"
                                value="단순 광고, 반복성 메시지"
                                name="reportReason"
                                onChange={handleReasonChange}
                                checked={selectedReason === '단순 광고, 반복성 메시지'}
                            />
                            <Form.Check.Label htmlFor="reason2">
                                단순 광고, 반복성 메시지
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check style={{ textAlign: 'left' }}>
                            <Form.Check.Input
                                type="radio"
                                id="reason3"
                                value="음란물/성적인 내용"
                                name="reportReason"
                                onChange={handleReasonChange}
                                checked={selectedReason === '음란물/성적인 내용'}
                            />
                            <Form.Check.Label htmlFor="reason3">
                                음란물/성적인 내용
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check style={{ textAlign: 'left' }}>
                            <Form.Check.Input
                                type="radio"
                                id="reason4"
                                value="명확함, 조건을 건 메시지"
                                name="reportReason"
                                onChange={handleReasonChange}
                                checked={selectedReason === '명확함, 조건을 건 메시지'}
                            />
                            <Form.Check.Label htmlFor="reason4">
                                명확함, 조건을 건 메시지
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check style={{ textAlign: 'left' }}>
                            <Form.Check.Input
                                type="radio"
                                id="reason5"
                                value="부적절한 메시지"
                                name="reportReason"
                                onChange={handleReasonChange}
                                checked={selectedReason === '부적절한 메시지'}
                            />
                            <Form.Check.Label htmlFor="reason5">
                                부적절한 메시지
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check style={{ textAlign: 'left' }}>
                            <Form.Check.Input
                                type="radio"
                                id="reason6"
                                value="기타사유"
                                name="reportReason"
                                onChange={handleReasonChange}
                                checked={selectedReason === '기타사유'}
                            />
                            <Form.Check.Label htmlFor="reason6">
                                기타사유(직접입력) - 100자내
                            </Form.Check.Label>
                        </Form.Check>
                        {selectedReason === '기타사유' && (
                            <Form.Group controlId="additionalInfo">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    maxLength={100}
                                    placeholder="기타 사유를 입력하세요"
                                    value={additionalInfo}
                                    onChange={handleAdditionalInfoChange}
				    style={{fontSzie: '0.7rem'}}
				    className="custom-placeholder"
                                />
                            </Form.Group>
                        )}
	    		<hr />
	    		{alertMessage&&(
				<div style={{width: '100%', textAlign:'center', color: 'red', fontSize: '0.7rem', fontWeight:'bold'}}>
				    이미 신고하셨습니다.
				</div>
			)}
                        <Row className="d-flex justify-content-between mt-3">
	    		    <Col></Col>
	    		    <Col>
                            <Button variant="primary" onClick={handleSubmit} style={{fontSize: '0.7rem', padding: '0.5rem'}}>
                                신고하기
                            </Button>
	    		    </Col>
	    		    <Col>
                            <Button variant="secondary" onClick={handleClose} style={{fontSize: '0.7rem', padding: '0.5rem'}}>
                                취소하기
                            </Button>
	    		    </Col>
	    		    <Col></Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ReportMessage;

