import React, { useEffect } from 'react';

const LoadableComponent = ({ Component, onLoad, ...props }) => {
  useEffect(() => {
    const loadData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 로딩 시간 시뮬레이션
      onLoad(); // 로딩 완료 후 콜백 호출
    };

    loadData();
  }, [onLoad]);

  return <Component {...props} />;
};

export default LoadableComponent;

