// Loading.js
import React from 'react';

const Loading = ({ loading }) => (
  loading ? (
    <div style={overlayStyle}>
      <div style={spinnerStyle}></div>
    </div>
  ) : null
);

const overlayStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // 투명한 배경
  zIndex: 9999, // 다른 요소보다 위에 배치
};

const spinnerStyle = {
  width: '50px',
  height: '50px',
  border: '5px solid #f3f3f3', // 스피너의 바깥 테두리
  borderTop: '5px solid #3498db', // 스피너의 회전 부분
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
};

// 스피너 애니메이션
const spinnerAnimation = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// 애니메이션을 페이지 스타일에 추가
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(spinnerAnimation, styleSheet.cssRules.length);

export default Loading;

