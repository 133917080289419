import {useState, useEffect} from 'react';
import {Card, Col, Row, Form, Image, InputGroup, Button} from 'react-bootstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

function OpenClass({ isCancelButtonVisible, setIsCancelButtonVisible, classRid }){
	const textCenter = {
		textAlign: 'center',
		justifyContent: 'center'
	};
	const paddingMargin0 = { padding:0, margin:0 };
	const paddingMargin1 = { paddingLeft: '15px', paddingRight:'0', margin:0 };
	const cTitle = {marginTop:'0.6rem', marginBottom: '0.2rem', fontWeight:'bold'};
	const cTitleFirst = { marginBottom: '0.2rem', fontWeight:'bold'};
	const blankStyle={fontSize:'0.6rem', color:'red', paddingLeft: '0.3rem'};
	const cardOutStyle = {backgroundColor: '#8080806e', position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, zIndex: 1001};

	const [classInfo, setClassInfo] = useState(null);
	//제목변수
	const [classTitle, setClassTitle] = useState(null);
	const handleTitle = (event) => {
		const temp = event.target.value;
		setClassTitle(temp);
	};
	//장소 변수
	const [classLocation, setClassLocation] = useState(null);
	const handleLocation = (event) => {
		const temp = event.target.value;
		setClassLocation(temp);
	};
	//코넨크 변수
	const [classComment, setClassComment] = useState(null);
	const handleComment = (event) => {
		const temp = event.target.value;
		setClassComment(temp);
	};
	//모임종류 백그라운드 색상 변경
	const [selectedOption, setSelectedOption] = useState(null);
	const handleRadioChange = (value) => {
		setSelectedOption(value);
	};
	//캘린더 추가
	const [classDate, setClassDate] = useState(null);
	const handleDate = (event) => {
		const temp = event.target.value;
		console.log('handleDate: ',temp);
		setClassDate(temp);
	}
	//시간 추가
	const [classTime, setClassTime] = useState(null);
	const handleTime = (event) => {
		const temp = event.target.value;
		console.log('handleTime: ',temp);
		setClassTime(temp);
	};
	//참여인언 숫자만
	const [numberValue, setNumberValue] = useState('');
	const onlyNumber = (event) => {
		const inputValue = event.target.value;
		if (inputValue === '' || (/^\d+$/.test(inputValue) && parseInt(inputValue) <= 100)) {
			setNumberValue(inputValue);
		}
	};
	//공개범위 설정
	const [classViewArea, setClassViewArea] = useState(null);
	const handleClassViewArea = (value) => {
		setClassViewArea(value);
	};
	//위치 없을 경우 disabled 하는 변수
	const [isMainLocationDisabled, setIsMainLocationDisabled] = useState(true);
	const [isSubLocationDisabled, setIsSubLocationDisabled] = useState(true);
	//토큰을 통해 메인/서브 동네 가져오기
	const [myLocationInfo, setMyLocationInfo] = useState(null);
	useEffect(()=>{
		const token = localStorage.getItem('token');
		const fetchData = async() => {
			const response = await axios.post(`${apiUrl}/MyLocation`, {myToken: token, classRid: classRid});
			await setMyLocationInfo(response.data.myLocation);
			
			switch (response.data.checkChatLo) {
			    case 'main':
				setIsMainLocationDisabled(false);
				break;
			    case 'sub':
				setIsSubLocationDisabled(false);
				break;
			    default:
				setIsMainLocationDisabled(true);
				setIsSubLocationDisabled(true);
			}
		}
		fetchData();
		console.log('OpenClass RID :',classRid);
	},[]);
	//입력 안할경우 값 출력하는 내용들
	const [titleBlank, setTitleBlank] = useState(false);
	const [optionBlank, setOptionBlank] = useState(false);
	const [placeBlank, setPlaceBlank] = useState(false);
	const [commentBlank, setCommentBlank] = useState(false);
	const [dateBlank, setDateBlank] = useState(false);
	const [timeBlank, setTimeBlank] = useState(false);
	const [numberBlank, setNumberBlank] = useState(false);
	const [viewAreaBlank, setViewAreaBlank] = useState(false);
	//개최하기 버튼 클릭
	const [createClass, setCreateClass] = useState(false);
	const handleCreateMeeting = async() => {
	    // 개최하기 버튼 클릭 시 상태에 저장된 값들을 출력
	    if(!classTitle){ setTitleBlank(true); return;} else{ setTitleBlank(false);}
	    if(!selectedOption){ setOptionBlank(true); return;} else{setOptionBlank(false);}
	    if(!classLocation){ setPlaceBlank(true); return;} else{setPlaceBlank(false);}
	    if(!classComment){ setCommentBlank(true); return;} else{setCommentBlank(false);}
	    if(!classDate){ setDateBlank(true); return;} else{setDateBlank(false);}
	    if(!classTime){ setTimeBlank(true); console.log('hihihihiih'); return;} else{console.log(classTime);setTimeBlank(false);}
	    if(!numberValue){ setNumberBlank(true); return;} else{setNumberBlank(false);}
	    if(!classViewArea){ setViewAreaBlank(true); return;} else{setViewAreaBlank(false);}

	    //new Date 형식으로 변경 / ISO 8601 형식으로 변경
	    const dateObject = new Date(`${classDate}T${classTime}:00`);

	    //값을 전달하고 저장함
	    const token = localStorage.getItem('token');
	    let response;
	    if(classRid){
		//classRid가 있을 경우
		    response = await axios.post(`${apiUrl}/CreateClass`, {
			    myToken: token,
			    cTitle: classTitle,
			    cOption: selectedOption,
			    cLocation: classLocation,
			    cComment: classComment,
			    cDateTime: dateObject,
			    cNumber: numberValue,
			    cViewArea: classViewArea,
			    cRid: classRid
		    });
	    }
	    if(response.data.message === 'success'){
		    setIsCancelButtonVisible(!isCancelButtonVisible);
	    }else{
		    await setCreateClass(true);
	    }
	
	    //값을 출력
	    console.log('Title:', classTitle);
	    console.log('모임종류: ', selectedOption);
	    console.log("장소 : ", classLocation);
	    console.log("코멘트: ", classComment);
	    console.log("개최일시: ", dateObject);
	    console.log('참여인원 : ', numberValue);
	    console.log('모임공개범위 : ', classViewArea);
	};
	//취소하기 버튼 클릭
	const handleCancelButtonClick = () => {
		setIsCancelButtonVisible(!isCancelButtonVisible);
	};

	//30분 단위
	const generateTimeOptions = () => {
		const times = [];
		for (let hour = 0; hour < 24; hour++) {
			const hourStr = String(hour).padStart(2, '0');
			times.push(`${hourStr}:00`);
			times.push(`${hourStr}:30`);
		}
		return times;
	};
	const timeOptions = generateTimeOptions();
	return(
		<>
		<style>{`
		.highlight{
		background-color: #ffb86e;
		border-radius: 15%
		}

			`}</style>
		{isCancelButtonVisible &&(
		<div style={cardOutStyle}>
		<Card border="secondary" style={{ width:'90%', top: '2rem', left: '5%', position:'fixed', zIndex:1040 }}>
		    <Card.Header><h6><b>모임 개최</b></h6></Card.Header>
			 <Card.Body>
			    <Card.Text>
				<div style={cTitleFirst}>제목</div>
				<Form.Control size="sm" type="text" style={{fontSize:'0.7rem'}} onChange={handleTitle} placeholder="모임제목을 입력해주세요" />
				<div style={blankStyle}>{titleBlank && '제목을 입력하세요'}</div>
				<div style={cTitle}>모임종류</div>
				<Row style={textCenter}>
				    <Col style={paddingMargin0}>
					<div className={selectedOption === "Amity" ? "highlight" : ""} onClick={() => handleRadioChange("Amity")}>
					    <Image src='/ClassAmity.png' fluid rounded /> 친목
					</div>
				    </Col>
				    <Col style={paddingMargin0}>
					<div className={selectedOption === "Exercise" ? "highlight" : ""} onClick={() => handleRadioChange("Exercise")}>
					    <Image src='/ClassExercise.png' fluid rounded /> 운동
					</div>
				    </Col>
				    <Col style={paddingMargin0}>
					<div className={selectedOption === "Hobby" ? "highlight" : ""} onClick={() => handleRadioChange("Hobby")}>
					    <Image src='/ClassHobby.png' fluid rounded /> 취미
					</div>
				    </Col>
				    <Col style={paddingMargin0}>
					<div className={selectedOption === "Etc" ? "highlight" : ""} onClick={() => handleRadioChange("Etc")}>
					    <Image src='/ClassEtc.png' fluid rounded /> 기타
					</div>
				    </Col>
				</Row>
				<div style={blankStyle}>{optionBlank && '종류를 선택해주세요'}</div>
				<div style={cTitle}>장소</div>
				<Form.Control size="sm" type="text" style={{fontSize:'0.7rem'}} onChange={handleLocation} placeholder="모임 장소이름을 입력해주세요" />
				<div style={blankStyle}>{placeBlank && '장소를 입력해주세요'}</div>
				<div style={cTitle}>코멘트</div>
				<Form.Control size="sm" type="text" style={{fontSize:'0.7rem'}} onChange={handleComment} placeholder="남기고 싶으신 코멘트를 남겨주세요" />
				<div style={blankStyle}>{commentBlank && '코멘트를 입력해주세요'}</div>
				<div style={cTitle}>개최일시</div>
				<Row>
				    <Col>
					<Form.Control type="date" onChange={handleDate} style={{fontSize:'0.7rem'}}/>
				    </Col>
				    <Col>
					<Form.Control as="select" onChange={handleTime} style={{fontSize:'0.7rem'}} >
					    {timeOptions.map((time) => (
						<option key={time} value={time}>
						    {time}
						</option>
					    ))}
					</Form.Control>
				    </Col>
				</Row>
				<div style={blankStyle}>{dateBlank && '날짜를 입력해주세요'}</div>
				<div style={blankStyle}>{timeBlank && '시간을 입력해주세요'}</div>
				<div style={cTitle}>참여인원 설정</div>
				<Row>
				    <InputGroup>
					<Form.Control size="sm" type="number" aria-describedby="basic-addon2" onChange={onlyNumber} value={numberValue}  max={100} style={{fontSize:'0.7rem'}}/>
					<InputGroup.Text id="basic-addon2" style={{fontSize:'0.7rem'}}>명</InputGroup.Text>
				    </InputGroup>
				<div style={blankStyle}>{numberBlank && '  참여인원 수를 설정하세요.'}</div>
				</Row>
				<div style={cTitle}>모임 공개범위</div>
				<Row>
				    <Col style={paddingMargin1}> <Form.Check inline  style={{fontSize:'0.7rem'}} label={myLocationInfo&&(myLocationInfo.mainlo)+' 전체'} name='viewArea' type='radio' id='mainChatRoom' onChange={() => handleClassViewArea('mainChatRoom')} checked={classViewArea === 'mainChatRoom'} disabled={isMainLocationDisabled&&(isMainLocationDisabled)}/>
				    </Col>
				    <Col style={paddingMargin0}> <Form.Check inline style={{fontSize:'0.7rem'}} label={myLocationInfo&&(myLocationInfo.sublo)+' 전체'} name='viewArea' type='radio' id='subChatRoom' onChange={() => handleClassViewArea('subChatRoom')} checked={classViewArea === 'subChatRoom'} disabled={isSubLocationDisabled&&(isSubLocationDisabled)}/>
				    </Col>
				</Row>
				<Row>
				    <Col style={paddingMargin1}> <Form.Check inline style={{fontSize:'0.7rem'}} label={myLocationInfo&&(myLocationInfo.mainlo)+' 소속채팅방'} name='viewArea' type='radio' id='mainChatRoomOnly' onChange={() => handleClassViewArea('mainChatRoomOnly')} checked={classViewArea === 'mainChatRoomOnly'} disabled={isMainLocationDisabled&&(isMainLocationDisabled)}/>
				    </Col>
				    <Col style={paddingMargin0}> <Form.Check inline style={{fontSize:'0.7rem'}} label={myLocationInfo&&(myLocationInfo.sublo)+' 소속채팅방'} name='viewArea' type='radio' id='subChatRoomOnly' onChange={() => handleClassViewArea('subChatRoomOnly')} checked={classViewArea === 'subChatRoomOnly'} disabled={isSubLocationDisabled&&(isSubLocationDisabled)}/>
				    </Col>
				</Row>
				<div style={blankStyle}>{viewAreaBlank && '공개범위를 선택하세요.'}</div>
				<Row style={{marginTop:'0.5rem'}}>
				    <Col className="d-flex justify-content-center"><Button variant="warning" style={{fontSize:'0.7rem'}} onClick={handleCreateMeeting}>개최하기</Button>{' '}</Col>
				    <Col className="d-flex justify-content-center"><Button variant="secondary" style={{fontSize:'0.7rem'}} onClick={handleCancelButtonClick}>취소하기</Button>{' '}</Col>
				</Row>
			    </Card.Text>
			</Card.Body>
		</Card>
		</div>
		)}
		</>
	);
}

export default OpenClass;
