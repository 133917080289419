import {Row, Col, Button, Image, Ratio} from 'react-bootstrap';
import { isEqual } from 'lodash';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react'; 
import axios from 'axios';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

//style 모음
const timeStyle={
	fontSize: '0.6rem',
	margin: 0,
	textAlign: 'right'
};
const thumbNailStyle = {
	padding: 0,
	margin: 0
};
const ChatRoomName = {
	padding: 0,
	margin: 0,
	fontWeight: 'bold'
};
const displayDot = {
	overflow: 'hidden', 
	textOverflow : 'ellipsis', 
	whiteSpace: 'nowrap',
	display: 'block',
	color: '#787878'
};

//시간 변경하는 함수
const getTimeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diff = Math.floor((now - then) / 1000); // difference in seconds
  if(timestamp){
      if (diff < 60) {
        return '방금 전';
      } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}분 전`;
      } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}시간 전`;
      } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)}일 전`;
      } else {
        return '오래 전';
      }   
  }else{
      return 'ㅡ';
  }
};



function ChatMyLocation() {

	const token = localStorage.getItem('token');
	const [roomInfo, setRoomInfo] = useState([]);
	useEffect(() => {
	    
	    const roomInfoListFunc = async () => {
		const response = await axios.post(`${apiUrl}/ChatRoomListNormal`, {myToken: token});
		console.log('ChatRoomListNormal : ', response.data);
		setRoomInfo(response.data);
	    };
	  
	    roomInfoListFunc();

	},[]);

	const myChatRoomList = roomInfo.map((v) =>(
	<Link style={{textDecoration: 'none', color: 'black'}} to={'/chatRoomNormal/'+v.rid}>
	    <Row style={{marginTop:'18px'}}>
		<Col xs={2}>
		    <Ratio aspectRatio='1x1'>
			<Image src={v.userInfo.pimage} fluid rounded />
		    </Ratio>
		</Col>
		<Col xs={7}>
		    <Row>
			<span style={{padding: 0}}>{<span style={ChatRoomName}>{v.userInfo.nname}</span>}</span>
		    </Row>
		    <Row style={displayDot}>
			{v.lastMessage[0] &&
			    (v.lastMessage[0].startsWith("https://innerong-profile-image") &&
			    (v.lastMessage[0].endsWith(".jpg") || v.lastMessage[0].endsWith(".jpeg") || v.lastMessage[0].endsWith(".png") || v.lastMessage[0].endsWith(".gif"))
			    ? "이미지"
			    : v.lastMessage[0])}
		    </Row>
		</Col>
		<Col xs={3} style={timeStyle}>{getTimeAgo(v.lastDate)}</Col>
	    </Row>
	</Link>
	));

	return(
		<div style={{textAlign:'left', marginTop: '25px'}}>
		    <Row>
		    <h5 style={{marginBottom:0}}><b>채팅방</b></h5>
		    </Row>
		{myChatRoomList}
		<br /><br /><br /><br /></div>
	);
}

export default ChatMyLocation;
