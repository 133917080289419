import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import MyProfile from './MyProfile.js';
import FriendRequest from './FriendRequest.js';
import FriendList from './FriendList.js';
import Loading from '../Loading'; // 로딩 컴포넌트 임포트
import LoadableComponent from '../LoadableComponent'; // 로딩 관리 컴포넌트 임포트

function FriendMain() {  // 컴포넌트 이름은 대문자로 시작합니다
  const [myProfileLoaded, setMyProfileLoaded] = useState(false);
  const [friendRequestLoaded, setFriendRequestLoaded] = useState(false);
  const [friendListLoaded, setFriendListLoaded] = useState(false);

  const [allComponentsLoaded, setAllComponentsLoaded] = useState(false);

  useEffect(() => {
    // 모든 컴포넌트가 로딩되었는지 확인
    setAllComponentsLoaded(myProfileLoaded && friendRequestLoaded && friendListLoaded);
  }, [myProfileLoaded, friendRequestLoaded, friendListLoaded]);

  return (
    <div>
      {!allComponentsLoaded && <Loading loading={!allComponentsLoaded} />} {/* 로딩 중 표시 */}
      <Container style={{ textAlign: 'left', padding: 0 }}>
        <div>
          <LoadableComponent Component={MyProfile} onLoad={() => setMyProfileLoaded(true)} />
          <LoadableComponent Component={FriendRequest} onLoad={() => setFriendRequestLoaded(true)} />
          <LoadableComponent Component={FriendList} onLoad={() => setFriendListLoaded(true)} />
        </div>
      </Container>
      <br />
    </div>
  );
}

export default FriendMain;

