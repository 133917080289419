import { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col, Image, Button, Modal } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';

function ClassView({ classId, onCloseView }) {

    const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

    const fsize07rem = { fontSize: '0.7rem' };
    const classDate = { fontSize: '0.8rem', fontWeight: 'bold', color:'#4e4b4b' };
    const classComment = { fontSize: '0.7rem', marginTop: '5px' };
    const classWhere = { fontSize: '0.7rem', marginTop: '5px' };
    const classWhereIn = { fontWeight: 'bold', paddingLeft: 0 };
    const classJoinUser = { fontSize: '0.7rem', margin: '0.5rem 0', borderRadius: '0.5rem', padding: '0.2rem 0.3rem', textAlign: 'center', backgroundColor: '#ffc107' };
    const nNameOut = { display: 'contents' };
    const nNameIn = { textAlign: 'center', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', padding: 0, whiteSpace: 'nowrap' };
    const fontBold = { fontWeight: 'bold', fontSize: '0.8rem' };
    const cardOutStyle = { position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, zIndex: 2};
    const cardInStyle = {position: 'fixed', top:'11rem', left: '5%', width: '90%'}
    const connectCheckStyle ={fontSize: '0.8rem', width: '100%'};
    const resultStyle = {color: 'red', fontSize: '0.7rem', textAlign: 'center', marginBottom: '0.7rem'};

    const settings = {
        infinite: false,
        adaptiveHeight: true,
        speed: 500,
        draggable: false,
        dots: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        swipeToSlide: false,
        textAlign: 'left',
        prevArrow: <></>,
        nextArrow: <></>
    };

    const [cJoinTotalUser, setCJoinTotalUser] = useState([]);
    const [classDetail, setClassDetail] = useState(null);
    const [alreadyJoinCheck, setAlreadyJoinCheck] = useState(false);
    const [isItClassMaster, setIsItClassMaster] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchClassDetail = async () => {
            try {
                const response = await axios.post(`${apiUrl}/viewClassDetail`, { myToken: token, classId: classId });
                setClassDetail(response.data.viewClassDetail);
		setAlreadyJoinCheck(response.data.alreadyJoinCheck);
		setIsItClassMaster(response.data.isItClassMaster);
		console.log('fetchClassDetail : ', response.data);
            } catch (error) {
                console.error('Error fetching class detail:', error);
            }
        };
	console.log('useEffect 1');
        if (classId) {
            fetchClassDetail();
        }
    }, [classId, token]);

    const [cNowTotalUser, setCNowTotalUser] = useState([]);

    useEffect(() => {
        if (classDetail && classDetail.classId) {
            const openUserArray = typeof classDetail.cOpenUser === 'string' ? [classDetail.cOpenUser] : classDetail.cOpenUser;
            setCNowTotalUser([...openUserArray, ...classDetail.cNowUser]);
        }
        console.log('useEffect 2');
    }, [classDetail]);

    useEffect(() => {
        const fetchClassJoinUser = async () => {
            if (cNowTotalUser.length > 0) {
                try {
                    const response = await axios.post(`${apiUrl}/viewClassJoinUser`, { myToken: token, cJoinUser: cNowTotalUser });
                    setCJoinTotalUser(response.data);
                    console.log('###########ClassView###########');
		    console.log(cNowTotalUser);
                    console.log(response.data);
		    console.log(classDetail);
		    console.log(cNowTotalUser);
		    console.log('show joinUser data : ',response.data);
		    console.log(token);
                    console.log('###############################');
                } catch (error) {
                    console.error('Error fetching class join user:', error);
                }
            }
        };

        fetchClassJoinUser();
    }, [cNowTotalUser, token]);


    const ViewJoinUser = cJoinTotalUser.map((v, index) => (
        <Col xs={2} key={index}>
	    <Link to={'/friendProfile/' + v.uid} className='justify-content-center align-items-center' style={{display:'contents',textDecoration:'none', color:"black"}}>
                <Row><Image src={v.pimage} fluid rounded style={{height:"2.5rem"}} /></Row>
                <Row style={nNameOut}><div style={nNameIn}>{v.nname}</div></Row>
	    </Link>
        </Col>
    ));

    const numberOfJoinUser = cJoinTotalUser.length;

    // 날짜 포맷팅 함수

    function formatDate(isoString) {
        const date = new Date(isoString);

        // 연도, 월, 일 추출
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        const day = String(date.getDate()).padStart(2, '0');

	// 요일 추출
        const daysOfWeek = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
        const dayOfWeek = daysOfWeek[date.getDay()];

        // 포맷된 날짜 문자열 생성
        const formattedDate = `${year}년 ${month}월 ${day}일 (${dayOfWeek})`;

        return formattedDate;
    };


    function formatTime(isoString) {
        const date = new Date(isoString);

        // 시간, 분 추출 및 오전/오후 결정
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? '오후' : '오전';
        if (hours > 12) {
            hours -= 12; // 12시간 형식으로 변환
        } else if (hours === 0) {
            hours = 12; // 0시는 12시로
        }

        // 포맷된 시간 문자열 생성 (요일 포함)
        const formattedTime = `${ampm} ${hours}시${minutes}분`;

        return formattedTime;
    };



    // ctype에 따라 이미지를 반환하는 함수
    const checkType = (MyType) => {
        let category = 0;
        switch (MyType) {
            case '1': category = "/ClassAmity.png"; break;
            case '2': category = "/ClassExercise.png"; break;
            case '3': category = "/ClassHobby.png"; break;
            case '4': category = "/ClassEtc.png"; break;
            default: category = "/ClassEtc.png"; break;
        }
        return category;
    };

    const [joinSuccessMessage, setJoinSuccessMessage] = useState(false);
    const [secessionSuccessMessage, setSecessionSuccessMessage] = useState(false);
    const [joinClassCheck, setJoinClassCheck] = useState(false);

    const joinClassModal = () => {
	console.log('joinClassModal In');
	setJoinClassCheck(!joinClassCheck);
    };

    const joinClassFunction = async(classId, nowState) =>{
	//nowSate가 true면 참가한 상태, false면 아직 참여하지 않은 상태
	console.log(classId);
	console.log(nowState);
	const response = await axios.post(`${apiUrl}/joinClassFunction`, { myToken: token, classId: classId, nowSate: nowState });
	switch (response.data.message){
	    case 'joinSuccess' : 
			setJoinSuccessMessage(true);
			setAlreadyJoinCheck(true);
			setSecessionSuccessMessage(false);
			setJoinClassCheck(false);
			setClassDetail(response.data.viewClassDetail);
			break;
	    case 'secessionSuccess' : 
			setJoinSuccessMessage(false);
			setAlreadyJoinCheck(false); 
			setSecessionSuccessMessage(true);
			setJoinClassCheck(false);
			setClassDetail(response.data.viewClassDetail);
			break;
	    default: 
			setJoinClassCheck(false);
			break;
	}
    };

    //모임삭제
    const [deleteCheck, setDeleteCheck] = useState(false);
    const deleteFormSet = () => {
	setDeleteCheck(!deleteCheck);
    };
    const deleteClassFunction = async(classId) => {
	console.log(classId);
	const response = await axios.post(`${apiUrl}/deleteClassFunction`, { myToken: token, classId: classId });
	setJoinClassCheck(false);
	setClassDetail(null);
    };


    return (
        <div style={cardOutStyle}>
	    {joinClassCheck && (
		<Modal centered show ={joinClassCheck}>
		    <Modal.Body style={{textAlign:'center', marginTop: '1rem'}}>
		    {alreadyJoinCheck
			    ? '모임에 불참하시겠습니까?'
			    : ' 모임에 참여하시겠습니까?'
		    }
		    </Modal.Body>
		        <Row style={{marginBottom: '2rem'}}>
		    	    <Col></Col>
		    	    <Col>
		    	        <Button variant="secondary" onClick={() => joinClassFunction(classDetail.classId, alreadyJoinCheck)} style={connectCheckStyle}>
			   	    네
		    	 	</Button>
		    	    </Col>
		    	    <Col>
		    		<Button variant="warning" onClick={() => joinClassModal() }  style={connectCheckStyle}>
		    		    아니오
		    		</Button>
		    	    </Col>
		    	    <Col></Col>
		    	</Row>
		</Modal>
	    )}
	    {deleteCheck && (
		<Modal centered show ={deleteCheck}>
		    <Modal.Body style={{textAlign:'center', marginTop: '1rem'}}>
		        모임을 삭제하시겠습니까?
		    </Modal.Body>
		    <Row style={{marginBottom: '2rem'}}>
		        <Col></Col>
		    	<Col>
		    	    <Button variant="secondary" onClick={() => deleteClassFunction(classDetail.classId)} style={connectCheckStyle}>
		    		네
		    	    </Button>
		        </Col>
		        <Col>
		    	    <Button variant="warning" onClick={() => deleteFormSet() }  style={connectCheckStyle}>
		    		아니오
		    	    </Button>
		        </Col>
		        <Col></Col>
		    </Row>
		</Modal>
	    )}
            {classDetail  && (
	    <div>
		    <Card style={cardInStyle}>
                    <Card.Header>
		        <h6><b>{classDetail.cTitle}</b></h6>
			    {isItClassMaster && (
				<div style={{position:'absolute', right:'0.5rem', top:'0.5rem'}}>
				    <TrashFill style={{color: 'red'}} onClick={()=>deleteFormSet()} size={17}/>
				</div>
			    )}
		    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Row>
                                <Col xs={4}>
                                    <Image src={checkType(classDetail.ctype)} fluid rounded />
                                </Col>
                                <Col xs={8}>
                                    <Row style={classDate}>{formatDate(classDetail.cOpenDate)}</Row>
		    		    <Row style={classDate}>{formatTime(classDetail.cOpenDate)}</Row>
                                    <Row style={classComment}>{classDetail.cComment}</Row>
                                    <Row style={classWhere}><Col xs={2} style={classWhereIn}>장소</Col><Col>{classDetail.cWhere}</Col></Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}><div style={classJoinUser}>남은인원 <span style={fontBold}>{classDetail.cTotalNum - (classDetail.cNowUser.length + 1)}</span></div></Col>
                                <Col xs={4}><div style={classJoinUser}>모집인원 <span style={fontBold}>{classDetail.cTotalNum}</span></div></Col>
                            </Row>
                            <Row>
                                <div style={{ width: '100%' }}>
                                    <Slider {...settings} style={{ display: 'block', position: 'static' }}>
                                        {ViewJoinUser}
                                        {Array.from({ length: 6 - numberOfJoinUser }).map((_, index) => (
                                            <Col key={index}></Col>
                                        ))}
                                    </Slider>
                                </div>
                            </Row>
                            <Row>
		    		<div style={resultStyle}> {joinSuccessMessage && '모임에 참여하였습니다.'} </div>
		    		<div style={resultStyle}> {secessionSuccessMessage && '모임을 나가셨습니다.' }</div>
                                <Col></Col>
                                <Col>{
				    alreadyJoinCheck ?
                                        <Button variant="warning" style={fsize07rem} onClick={()=>joinClassModal()} disabled={isItClassMaster}>불참</Button>
					:
					<Button variant="warning" style={fsize07rem} onClick={()=>joinClassModal()} disabled={isItClassMaster}>참여</Button>
				}
                                </Col>
                                <Col>
                                    <Button variant="secondary" style={fsize07rem} onClick={onCloseView}>닫기</Button>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Card.Text>
                    </Card.Body>
                </Card>
	    </div>
            )}
        </div>
    );
}

export default ClassView;

