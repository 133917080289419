import {Container, Row, Col, Button, Image, Ratio, Modal} from 'react-bootstrap';
import {PinAngle} from 'react-bootstrap-icons';
import React, {useState, useEffect} from 'react';
import {isEqual} from 'lodash';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';

const fontSizeSmall={fontSize: '0.8rem', width: '100%'};

function ConfirmLocation(props) { 

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const token = localStorage.getItem('token');

	///location/params 값 전달받는 부분
	//myLocation 과 oftenLocation 이 아니면 모두 myLocation으로 변경
	const tempParamsLo = props.paramslo;
	const paramsLo = isEqual(tempParamsLo, "oftenLocation") ? "oftenLocation" : "myLocation";
	//데이터 받아오기 JWT 복호화 데이터
        const [myInfo, setData] = useState(null);
	const [nowLocation, setNowLo] = useState(null);

	const [showModal, setShowModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	const handleClose = () => setShowModal(false);


        useEffect(()=>{
                const fetchData = async() => {
                        const token = localStorage.getItem('token');
                        const response = await axios.post(`${apiUrl}/ConfirmLocation`,{ myToken: token });
                        await setData(response.data);
			const myLocation = response.data.loginUser.mainlo;
			const oftenLocation = response.data.loginUser.sublo;
			setNowLo(isEqual(paramsLo, 'oftenLocation') ? oftenLocation : myLocation);
			console.log('paramsLo: ', paramsLo);
                };
                fetchData();
        },[paramsLo, token]);

	const [message, setMessage] = useState('');
	const receiveMessage = async (latitude, longitude) => {
		try{
			//행정동 정보 가져오기
			console.log('receiveMessage: ',paramsLo);
			const response = await axios.post(`${apiUrl}/GeoCode`, {
				latitude,
				longitude,
				myToken: token,
				paramsLo, paramsLo
			});
			switch (response.data.res) {
			    case 'Y':
			        console.log('Location 데이터: ', response.data);
			        setNowLo(response.data.fullAddress);
			        localStorage.setItem('token', token);
			        setMessage(response.data.message);
				setShowModal(true);
			        break;

			    case 'SP':
			        setMessage(response.data.message);
				setShowModal(true);
			        break;

			    default:
			        // 만약 예상치 못한 값이 들어왔을 때 처리할 내용이 있으면 여기에 작성합니다.
			        console.warn('Unknown response code:', response.data.res);
			        break;
			}

		} catch (error) {
			console.error('Failed to process location data:', error);
			alert('좌표 데이터를 처리하는 중 오류가 발생했습니다.');
		}
	};

	const requestLocation = () => {
		 if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
			window.ReactNativeWebView.postMessage('getLocation');
			//receiveMessage('37.54918', '127.0156');
		}else{
			console.error('ReactNativeWebView is not defined.');
			alert('This function can only be used within the WebView.');
		}
	};

	const receiveLocation = async (event) => {
		try {
			const locationData = JSON.parse(event.data);
			console.log('Received location:', locationData);
			alert('locationData: ' + JSON.stringify(locationData));
			//latitude (위도)랑 longitude(경도) 로 표시된다.
			const { latitude, longitude } = locationData;
			//alert(JSON.stringify(locationData));
			alert('latitude: ' + latitude);
			alert('longitude: ' + longitude);
			//행정동 정보 가져오기
			console.log('receiveMessage: ',paramsLo);
			const response = await axios.post(`${apiUrl}/GeoCode`, {
				latitude,
				longitude,
				myToken: token,
				paramsLo, paramsLo
			});
			switch (response.data.res) {
			    case 'Y':
			        console.log('Location 데이터: ', response.data);
			        setNowLo(response.data.fullAddress);
			        localStorage.setItem('token', token);
			        setMessage(response.data.message);
				setShowModal(true);
			        break;

			    case 'SP':
			        setMessage(response.data.message);
				setShowModal(true);
			        break;

			    default:
			        // 만약 예상치 못한 값이 들어왔을 때 처리할 내용이 있으면 여기에 작성합니다.
			        console.warn('Unknown response code:', response.data.res);
			        break;
			}

		} catch (error){
			console.error('Failed to parse location data:', error);
		}
	};


	React.useEffect(() => {
		document.addEventListener('message', receiveLocation);

		// 크린업
		return () => {
			document.removeEventListener('message', receiveLocation);
		};
	}, []);

	return(
	<div>
		    <Modal show={showModal} centered>
		        <Modal.Body style={{fontSize: '0.8rem'}}>{message}</Modal.Body>
		        <Modal.Footer style={{textAlign: 'center', display: 'block'}}>
			    <Button variant="secondary" onClick={handleClose} style={{fontSize: '0.8rem'}}>
				닫기
			    </Button>
			</Modal.Footer>
		    </Modal>
		<h5><b>동네인증</b></h5>
		<Row className={fontSizeSmall}>
		    <Col>
			<Link to="/location/myLocation">
			    <Button variant="outline-warning" style={fontSizeSmall} active={isEqual(paramsLo,"myLocation")}>우리동네</Button>
			</Link>
		    </Col>
		    <Col>
			<Link to="/location/oftenLocation">
			    <Button variant="outline-warning" style={fontSizeSmall} active={isEqual(paramsLo,"oftenLocation")}>자주가는동네</Button>
			</Link>
		    </Col>
		</Row>
		<Row style={{marginTop:'10px'}}>
		    <Col xs={9}>
			<Button variant="secondary" style={fontSizeSmall} disabled> 
			    인증지역 : {nowLocation ? nowLocation : <b style={{color:'#ffc107', textDecorationLine: 'underline'}}> 위치인증 필요</b>}
			</Button>
		    </Col>
		    <Col xs={3} style={{paddingLeft: '0'}}>
			<Button 
				variant="outline-secondary" 
				style={{fontSize:'0.8rem', width:'100%', marginLeft:'0'}}
				onClick={requestLocation}
			>
			    <PinAngle />인증
			</Button>
		    </Col>
		</Row>
	</div>
	);
}

export default ConfirmLocation;
